import * as React from "react"
import Layout from '../components/layout'
import {Link} from 'gatsby'
import Logo from '../images/logos/5thStrand-Cutout.svg'
import PinkFullCircle from '../images/pink-full-circle.svg'
import YellowFullCircle from '../images/yellow-full-circle.svg'
import cmd from '../images/animated/cmd-outline.gif'
import puzzle from '../images/animated/puzzle-outline.gif'
import rhombus from '../images/animated/rhombus-outline.gif'
import document from '../images/animated/document-outline.gif'


import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

var ReactRotatingText = require('react-rotating-text');

const Services = () => {
  return (

    <Layout>
      <CustomCursor
      targets={['.link', '.your-css-selector']}
      customClass='custom-cursor'
      dimensions={50}
      fill='#222'
      opacity={1}
      smoothness={{
        movement: 0.2,
        scale: 0.1,
        opacity: 1,
      }}
      targetOpacity={0.5}
    />
    <main className="cont bg-5th-yellow nocursor scroll-smooth	">
      <div className="child sm:text-center flex justify-center items-center relative">
        <div className="w-5/6">
          <h1 className=" font-bold text-black text-4xl lg:text-8xl font-heading pb-6">
          OUR EXPERIENCE &&nbsp;EXPERTISE <span className="text-white">.</span>
          </h1>
          <p className="text-xl lg:text-3xl text-5th-pink font-heading">Here is a list of (some of) the things we do well.
         </p>
        </div>

        <div className="top-0 left-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={PinkFullCircle}/>
        </div>

        <div className="top-0 right-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={YellowFullCircle}/>
        </div>

        <div className="bottom-10 right-10 absolute ">
          <p className="text-5th-pink">PART OF THE <a target="_blank" href="https://urbanscaleinterventions.com/"><span className="link text-black">USI</span></a> FAMILY</p>
        </div>
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16 pt-24 lg:pt-0">
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4">WEBSITE & APP DEVELOPMENT <span className="text-white">.</span></h2>
            <p className="text-md lg:text-xl font-lower">
            We understand how important a website is. We use a collaborative approach for all our projects and start with co-design workshops so we fully comprehend your goals and needs.<br/><br/>
            With every site we make wireframes with UI and graphic designers and develop them with talented coders to produce pixel perfect digital transformations.
            After this we offer full maintenance, additional phases of site evolution and carbon offsetting hosting!
            </p>
          </div>

          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={cmd}/>
          </div>

        </div>
       
      </div> 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16 pt-12 lg:pt-0">
          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={rhombus}/>
          </div>
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4">UI & UX DESIGN <span className="text-white">.</span></h2>
            <p className="text-md lg:text-xl font-lower">
            UI refers to the user interface, all those clickable buttons, moving modals, tangible toggles and interesting icons. 
            It is how the user interacts with the app or website.<br/><br/>
            UX is user experience. This is the entire interaction a user has with a product and how they feel about it. 
            It means thinking about product strategy, user testing and information architecture.<br/><br/>
            When you tie both together it means you can offer a user a clean and easy to use interface while retaining exciting visual designs.
            </p>
          </div>

        </div>

     
       
      </div> 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16 pt-24 lg:pt-0">
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4">BRANDING <span className="text-white">.</span></h2>
            <p className="text-md lg:text-xl font-lower">
            Your brand is you. It's what people see when they look at your offering.
            We believe branding should be reflective of your values and like all our services we like to start this process with a co-design workshop.
            We get to understand your core values, mission statement and future plans.<br/><br/>
            Asking these questions lets us understand how the brand can be what it needs to be.
            This allows us to produce designs and reports that can lead your brand along its chosen path.
            </p>
          </div>

          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={puzzle}/>
          </div>

        </div>
       
      </div> 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 mx-auto gap-16 pt-12 lg:pt-0">
          <div className="mx-auto w-36 lg:w-96 z-10">
            <img className="w-full" src={document}/>
          </div>
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4">SEO AUDITS & CONTENT WRITING <span className="text-white">.</span></h2>
            <p className="text-md lg:text-xl font-lower">
            SEO is about controlling the flow of internet traffic to lead more people to your door.
            Our SEO audits allow us to fix content that is duplicated or too shallow, to optimize your site metadata and ensure your site runs at the speed it should.<br/><br/>
            As part of this, we have experience in content creation according to SEO optimized wording.
            We also have previously researched and written content for cultural trails and historic locations while liaising with local historians and academics.            
            </p>
          </div>

        </div>

       
      </div> 



      <footer className="snap-always snap-center p-4 sm:p-6 my-6 lg:my-16 mx-auto w-5/6 font-lower">
            <div className="md:flex md:justify-between items-center">
                <div className="mb-6 md:mb-0">
                    <Link to="/" className="flex items-center">
                        <img src={Logo} className="h-36" alt="5th Strand Logo"/>
                    </Link>
                </div>
                <div className="mb-6 md:mb-0">
                  <h1 className="text-black text-2xl sm:text-3xl md:text-4xl leading-relaxed font-bold ">5th Strand<br/><span className='font-normal leading-relaxed'>Believers in Change</span></h1>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-8">
                   
                   
                    <div>
                        <h2 className="mb-6 text-xs font-semibold text-black uppercase">Legal</h2>
                        <ul className="text-black text-xs">
                            <li className="mb-4">
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="mb-6 font-semibold text-black uppercase text-xs">Contact</h2>
                        <ul className="text-black text-xs">
                            <li className="mb-4">
                                <a href="mailto:studio@5thstrand.com" className="hover:underline text-xs">studio@5thstrand.com</a>
                            </li>
                            <li>
                                <a href="tel:+447754078144" className="hover:underline">Tel: 077 540 78144</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-700 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-black sm:text-center dark:text-gray-400">© 2022 <a href="" className="hover:underline">5th Strand USI</a>. All Rights Reserved.
                    </span>
                  
                </div>
        </footer>
      
       
    
    </main>
    </Layout>
  )
}

export default Services

export function Head() {
  return (
    <>
      <title>5th Strand - Services</title>
      <meta name="description" content="5th Strand can offer web and app development, branding, wireframes and SEO optimization." />
        <meta property="og:title" content="5th Strand - Services" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.5thstrand.com/services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </>  
    )
}